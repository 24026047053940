import React from 'react';
import { Home } from 'Molecules/PUC';

const Index = () => {
  return (
    <main role='main'>
      <Home />
    </main>
  );
};

export default Index;

export const getServerSideProps = async ({ req }) => {
  const { cookies } = req;
  if (cookies.token) {
    return {
      redirect: {
        destination: '/reservations',
        permanent: false,
      },
    };
  }
  return { props: {} };
};
